import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export type MeetingState = {
  room: MeetingContextRoom;
  participants: any[];
};

export interface MeetingContextRoom {
  name: string;
  type: string;
  variant: string;
  subject: string;
  status: string;
  time: string;
  starttime: string;
  endtime: string;
  duration: number;
  participants: any;
  uuid: string;
  expired: boolean;
  timezone: string;
}

const getMeetingDetailFromStorage = (key: string) => {
  const storageItem: any = sessionStorage.getItem(key)
  return JSON.parse(storageItem);
}

const storeMeetingDetail = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
}
export default function useMeeting() {

  const [meetingState, setMeetingState] = useState<MeetingContextRoom>({
    name: "Loading...",
    type: "",
    variant: "",
    subject: "",
    status: "",
    time: "",
    starttime: "",
    endtime: "",
    participants: [],
    duration: 0,
    uuid: "",
    expired: true,
    timezone: ""
  });
  const { meeting } = useParams<{meeting: undefined;}>();

  const useLS = false;

  const headers = new Headers({
    method: 'GET',
    dtoffset: `${new Date().getTimezoneOffset()}`
  });


  useEffect(() => {
    
    try {
      
      const ctx = getMeetingDetailFromStorage(meeting)
      
      if(ctx && useLS) {
        setMeetingState(ctx);
        return;
      }      
      
    } catch(e) {}
        
    if(meeting) {

      fetch(`/api/meeting/${meeting}`, { headers })
        .then((response) => response.json())
        .then((ctx: MeetingContextRoom) => {
          storeMeetingDetail(meeting, ctx);
          setMeetingState(ctx);
          return ctx;
        }).catch(e => console.log(e));
    }

  }, []);

   
  return meetingState;
}