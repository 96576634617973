import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import { constants } from 'zlib';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, meeting } = useParams<{URLRoomName: undefined; meeting: undefined}>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [friendlyRoomName, setFriendlyRoomName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [participant, setParticipant] = useState(null);

  const [validPasscode, setValidPasscode ] = useState<boolean>(false);

  const [mediaError, setMediaError] = useState<Error>();

  const [pType, setParticipantType] = useState<number>(0);
  
  const [isCRM, setIsCRM] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);

  const params = new URLSearchParams(window.location.search);

  /* for organizer */
  const isHost = (params.has("participanttype") && params.get("participanttype") === "1");

  const setParams = () => {
    if(!isHost) return;

    console.debug("params", Array.from(params.entries()));

    if(params.has("participanttype")) 
      setParticipantType(parseInt(params.get("participanttype") ?? "0"));

    if(params.has("participantname")) 
      setName( params.get("participantname") ?? "");

      if (params.has("passcode"))
          setPassword(params.get("passcode") ?? "");
    

    if(params.has("crmiq")) setIsCRM(true);

  }

  useEffect(() => {
    setParams();
  }, [])


  useEffect(()=> {
    if(!participant) return;

    fetch(`/api/meeting/participant/${participant}/waiting`, {
      method: 'POST'
    }).catch(error => console.error(error));
    
  }, [participant])

  useEffect(() => {

    try {
      
      //check for login state
      const event = JSON.parse(sessionStorage.getItem(`${meeting}.code`) ?? 'false');
      if(event) setStep(Steps.deviceSelectionStep);
    } catch(e) {}

  }, [])

  
  useEffect(() => {
    if (URLRoomName) {
      setRoomName(`${URLRoomName}_${meeting}`);
      setFriendlyRoomName(URLRoomName);

      const tempUser = sessionStorage.getItem(`${meeting}.name`)
      if(tempUser) setName(tempUser);

      if (user?.displayName || sessionStorage.getItem(`${meeting}.participant`)) {
        setStep(Steps.deviceSelectionStep);
      }
    }

  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  useEffect(() => {
    if(!next) return;
    setNext(false);
    nextStep("next");
  }, [next])

  useEffect(() => {
    /* If valid, return a participant record */
    if(password.length !== 6) return;

    fetch(`/api/meeting/${meeting}/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password, 
        participantname: name,
        participanttype: pType
      })
    })
    .then(response => response.json())
    .then(response => {

      setValidPasscode(response.valid);
      if(response.valid) sessionStorage.setItem(`${meeting}.code`, response.valid);

      setParticipant(response.participant.participantUuid)
      sessionStorage.setItem(`${meeting}.participant`, response.participant.participantUuid);
      
      if(response.valid && isCRM) setNext(true);

    }).catch(e => console.error(e))
  
  },  [password])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setNext(true);
  };

  const nextStep = (src="") => {
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/video/meeting/${meeting}/participant/${participant}/room/${friendlyRoomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  }

  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep}>
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          password={password}
          setName={setName}
          setRoomName={setRoomName}
          setFriendlyRoomName={setFriendlyRoomName}
          setPassword={setPassword}
          handleSubmit={handleSubmit}
          validPasscode={validPasscode}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} friendlyRoomName={friendlyRoomName} setStep={setStep} />
      )}
      
    </IntroContainer>
  );
}
