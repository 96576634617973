import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme, Card, CardContent, CardActionArea } from '@material-ui/core';
import { useAppState } from '../../../state';
import useMeeting, { MeetingContextRoom } from '../../../hooks/useMeeting/useMeeting';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  gutterTopBottom: {
    margin: '1em 0'
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  meetingName: {
    marginBottom: '1em',
    color: `${theme.palette.primary.light}`,
    "& a": {
      textDecoration: 'none',
    },
    "& a:hover": {
      textDecoration: 'underline',
    },
    "& a:visited": {
      color: `${theme.palette.primary.light}`,
      textDecorationColor: 'initial',
      textDecoration: 'initial',
      textDecorationStyle: 'dashed'
    }
  },
  meetingTimeDetails: {
    margin: '0 0 12px 0',
    paddingBottom: '8px',
    // textAlign: 'center'
    // borderBottom: '1px solid gray'
  },
  particpantDetail: {
    columnCount: 1,
    columnGap: 0,
    // columnWidth: '50%',
    // columnRule: `1px solid ${theme.palette.primary.main}`,
    margin: '2em 0',
  },
  participantColumn: {
    margin: '0 0 12px 0',
    padding: '0px 6px',
    // textAlign: 'center',
    "& div.participantColumn:nth-of-type(1)": {
      borderRight: '1px solid gray'
  
    }
  },
  colHead: {
    marginBottom: '10px',
    fontWeight: 'bold'
  },
  name: {
    fontWeight: 'initial',
    marginBottom: "8px",
    '& span[data-status="Pending"]' : {
      color: "orange"
    },
    '& span[data-status="Accepted"], span[data-status="Connected"]' : {
      color: "green"
    },
    '& span[data-status="Tentative"]' : {
      color: "purple"
    },
    '& span[data-status="Disconnected"], span[data-status="Declined"]' : {
      color: "red"
    },
   
  },
  particpantStatus: {
   fontSize: "12px"
  },
  expired: {
    marginTop: "24px"
  }
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  password: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  setFriendlyRoomName: (friendlyRoomName: string) => void;
  setPassword: (password: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  validPasscode: boolean;
}


export default function RoomNameScreen({ name, roomName, password, setName, setFriendlyRoomName, setRoomName, setPassword, handleSubmit, validPasscode }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const { meeting: meetingUuid, participant: participantUuid } = useParams<{meeting: undefined; participant: undefined;}>();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);

    sessionStorage.setItem(`${meetingUuid}.name`, event.target.value);

  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const timezone = () => {
    const date = new Date();
    const result = date.toString().match(/\(([A-Za-z\s].*)\)/);
    if(result && result.length > 1) {
      
      return {
        short: result[1].replace(/[a-z]|\s/g, ''),
        long: result[1]
      }
    }
    return "";
  }

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;
  
  const meeting = useMeeting();

  const [isRoomOpened, setRoomOpened] = useState(false);

  const [isMeetingValid, setIsMeetingValid] = useState(false);

  const [isExpired, setIsExpired] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const [variant, setVariant] = useState<string>('Scheduled Meeting');
  const [subject, setSubject] = useState<string>('Subject');
  const [time, setTime] = useState<string>('Meeting Time');
  const [propertyTime, setPropertyTime] = useState<string>('Meeting Time');
  // const [participants, setParticipants] = useState<any>([]);
  const [tz, setTz] = useState<any>({});

  useEffect(() => {

    setIsMeetingValid(true);
    setVariant(meeting.variant);
    setSubject(meeting.subject);
    // setParticipants(meeting.participants);
    setRoomName(meeting.uuid);
    setFriendlyRoomName(meeting.name);

    const now  = Date.now();
    const starttime = new Date(meeting.starttime);
    const endtime = new Date(meeting.endtime);
    const utc_starttime = starttime.getTime();
    const utc_endtime = endtime.getTime();
    const language = navigator.language;

    /* Values reflecting the meeting time based on the users browser time */
    const localeStartDate = starttime.toLocaleDateString();
    const localeStartTime = starttime.toLocaleTimeString().replace(/:\d{2}\s\w+$/, '');
    const localeEndTime = endtime.toLocaleTimeString(language).replace(/:\d{2}(?= (PM|AM))/, '');
    const localeTZ: any = timezone();

    setTz(localeTZ)

    /* this is a hack... Pacific/.* is not a valid timezone, and will break this */
    const meetingTimezone = (/pacific/i.test(meeting.timezone)) 
        ? meeting.timezone.replace(/pacific/i, 'America')
        : meeting.timezone;

    /* Values reflecting the meeting time based on the properties time zone */
    const propertyStartDate = starttime.toLocaleDateString(language, {timeZone: meetingTimezone})
    const propertyStartTime = starttime.toLocaleTimeString(language, {timeZone: meetingTimezone})
    const propertyEndTime   = endtime.toLocaleTimeString(language, {timeZone: meetingTimezone, timeZoneName: "long"})

    setTime(`${localeStartDate} ${localeStartTime} - ${localeEndTime} ${localeTZ.short}`);
    setPropertyTime(`${propertyStartDate} ${propertyStartTime} - ${propertyEndTime}`);
    
    let openedAt = utc_starttime - 15 * 60_000;

    if(meeting.status === "canceled") {
      setRoomOpened(false);
      setIsCanceled(true);
      setSubject(meeting.subject + " (Canceled)")
    } else {
      if(now > utc_endtime) return setIsExpired(true);
      if(now > openedAt) setRoomOpened(true);
    }

  }, [meeting])

  return (
    <>
      {!isMeetingValid && (
        <Grid container direction="column" alignItems="stretch" justify="center">
            <Card>
              <CardContent>
                <Typography variant="h5" className={classes.meetingName}>
                  Oops!
                </Typography>
                <Typography variant="body1">
                  I couldn't find your room.
                </Typography>
              </CardContent>
            </Card>
        </Grid>
      )}
 
      {isMeetingValid && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            {/* Meeting Type | tour, movein, moveout, maintenance, other */}
            {/* Live Tour */}
            {variant}
          </Typography>
          <Typography variant="h5" className={classes.meetingName}>
            {/* Meeting Name */}
            {subject}
          </Typography>
          <div className={classes.meetingTimeDetails}>
            <Typography variant="body1" title={tz.long}>
              {time}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              This meeting time has been adjusted to reflect your current time zone. The meeting was scheduled by the organizer for {propertyTime}.
            </Typography>
          </div>

          {isRoomOpened ? (
            <>
              <Typography variant="body1">
                {hasUsername
                  ? "Enter the name of a room you'd like to join."
                  : "To join, please enter your name and the 6 digit meeting code."}
              </Typography>
              <form onSubmit={handleSubmit}>
                <div className={classes.inputContainer}>
                  {!hasUsername && (
                    <div className={classes.textFieldContainer}>
                      <InputLabel shrink htmlFor="input-user-name">
                        Your Name
                      </InputLabel>
                      <TextField
                        id="input-user-name"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </div>
                  )}
                  {1 === 1+1 && (<div className={classes.textFieldContainer}>
                    <InputLabel shrink htmlFor="input-room-name">
                      Room Name
                    </InputLabel>
                    <TextField
                      autoCapitalize="false"
                      id="input-room-name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={roomName}
                      onChange={handleRoomNameChange}
                    />
                  </div>)}
                  <div className={classes.textFieldContainer}>
                    <InputLabel shrink htmlFor="input-room-name">
                      Meeting Code
                    </InputLabel>
                    <TextField
                      autoCapitalize="false"
                      id="input-room-name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                </div>
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!name || !roomName || !validPasscode}
                    className={classes.continueButton}
                  >
                    Continue
                  </Button>
                </Grid>
              </form>  
            </>
            ) : (
              <div>
                <Card className={isExpired ? classes.expired : ''}>
                  <CardContent>
                    <Typography variant="body1">

                      {(isExpired || isCanceled) ? (
                        `This meeting ${isExpired ? 'link has expired.' : (isCanceled ? 'was canceled by the organizer.': 'is unavailable.')}`
                      ) : (
                        'This meeting will open 15 minutes before the scheduled time.'
                      )}

                    </Typography>
                  </CardContent>
                </Card>
              </div>
            )}
        </>
      )}

   </>
  );
}
